import React from 'react';
import "./Take12.scss"
const Take12 = () => {
    return (
        <div id="catavento">
        <p> <span>desenvolvido por</span> Studio Catavento</p><br></br>
       <p> <span>Todos os direitos reservados - Copyright - 2023</span></p>
            <div className="espaco"></div>
        </div>
    );
}

export default Take12;
